import React, { useEffect, useState } from 'react';
import { Link, graphql } from 'gatsby';
import queryString from 'query-string';
import {
  Layout,
  SEO,
  CJumbotron,
  CBreadCrumb,
  LWrap,
  CGeneralMedia,
  CSectTitle,
  CSectTitle03,
  CDefinition,
  LOgata,
  CSimpleAccordionList,
  CustomLink,
} from '../../../../components/_index';
import shopGet from '../../../../utils/shop-get';
import menuChoice from '../../../../utils/menu-choice';
import menuGet from '../../../../utils/menu-get';

// markup
const SubPage = ({ data, location }: any) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const { contentId, draftKey } = queryString.parse(location.search);
  const [shopdata, setShopData] = useState<any | null>(shopGet('keikaen'));
  if (contentId) {
    useEffect(() => {
      fetch(
        `https://${process.env.MICRO_CMS_SERVICE_DOMAIN}.microcms.io/api/v1/shop_srph/${contentId}?draftKey=${draftKey}`,
        {
          headers: {
            'X-MICROCMS-API-KEY': process.env.MICRO_CMS_API_KEY || '',
          },
        }
      )
        .then((res) => res.json())
        .then((res) => setShopData(res));
    }, []);
  }

  return (
    <Layout isKeikaen={true}>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="exLarge"
        data={{
          title: {
            en: ' WEDDING',
            ja: 'レストランウエディング',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/restaurants/keikaen/wedding/kv.png',
              },
              imgSp: {
                src: '/assets/images/restaurants/keikaen/wedding/kv__sp.png',
              },
            },
          ],
        }}
        exClass="c_jumbotron_keikaen"
      >
        <CBreadCrumb
          data={{
            parent: [
              {
                label: 'レストラン',
                path: '/restaurants/',
              },
              {
                label: '中国料理「桂花苑」',
                path: '/restaurants/keikaen/',
              },
            ],
            current: {
              label: 'レストランウエディング',
            },
          }}
        />
      </CJumbotron>
      <section className="l_sect">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{
              main: (
                <>
                  CHINESE <br className="u_sp" />
                  RESTAURANT <br className="u_sp" />
                  WEDDING
                </>
              ),
              sub: '挙式＋会食',
            }}
          />
          <CGeneralMedia
            data={[
              {
                img: {
                  src: '/assets/images/restaurants/keikaen/wedding/img_wedding.png',
                  alt: '',
                },
                title: (
                  <>
                    新しい結婚式の選択肢
                    <br />
                    ホテルでレストラン
                    <br className="u_sp" />
                    ウエディング
                  </>
                ),
                text: (
                  <>
                    <p>
                      ひと味違うホテルのレストランで食事と会話を愉しむカジュアル＆リッチな結婚式。非日常のシノワズリの空間で気心の知れた方々と語り合う、アットホームウエディング。
                    </p>
                  </>
                ),
                btn: {
                  label: 'MAILでのお問合せ',
                  link: {
                    href: 'https://contact.royalparkhotels.co.jp/srph/contact?_ifbs-srph_contact_form=s1_Step1',
                    blank: true,
                  },
                  color: 'borderBlack',
                },
              },
            ]}
          />
          <CSimpleAccordionList
            data={[
              {
                title: <>プラン内容</>,
                content: (
                  <>
                    <p className="u_mb30">
                      料金
                      <br />
                      挙式＋会食の15名様でのお見積り例
                      <br />
                      756,700円
                    </p>
                    <p className="u_mb30">
                      内容
                      <br />
                      <ul className="c_circleList">
                        <li>料理</li>
                        <li>飲物</li>
                        <li>貸切料</li>
                        <li>チャペル挙式</li>
                        <li>ドレス／タキシード／小物</li>
                        <li>新郎新婦お支度</li>
                        <li>介添</li>
                        <li>ブーケ</li>
                        <li>テーブルコーディネート</li>
                      </ul>
                    </p>
                    <p className="u_mb30">
                      備考
                      <br />
                      土日祝の結婚式は3か月先までのお日にちで承ります。
                    </p>
                    <p className="c_sectLead u_mbMedium">オプション</p>
                    <p className="u_mb30">
                      <ul className="c_circleList">
                        <li>ウエディングケーキ 1名1,518円～</li>
                        <li>お子様料理 1,895円～</li>
                        <li>液晶ディスプレイ60インチ 33,000円</li>
                        <li>音響機材一式 38,500円</li>
                        <li>卓上装花 7,150円～</li>
                        <li>家族写真 19,800円～</li>
                        <li>スナップフォト 132,000円～</li>
                      </ul>
                      <ul className="c_noteList">
                        <li>
                          上記内容以外でのご利用をご希望の際はご相談ください。
                        </li>
                      </ul>
                    </p>
                  </>
                ),
              },
            ]}
          />
        </LWrap>
      </section>
      <section className="l_sect u_bgWhite">
        <LWrap>
          <CSectTitle03
            type="en"
            title={{ main: 'GUIDE', sub: '営業時間｜ご利用案内' }}
          />
          <CDefinition
            exClass="u_mb30"
            data={shopdata.information.map((item: any) => {
              return {
                title:
                  item.keyname &&
                  item.keyname.split('\n').map((t: string) => (
                    <>
                      {t}
                      <br />
                    </>
                  )),
                text: (
                  <div
                    className="wysiwyg"
                    dangerouslySetInnerHTML={{
                      __html: `${item.value}`,
                    }}
                  />
                ),
              };
            })}
          />
          <ul className="c_noteList">
            <li>掲載の内容は予告なく変更する場合があります。</li>
            <li>画像はイメージです。</li>
            <li>表示価格は消費税、サービス料込みの価格です。</li>
            <li>
              メニュー内容は仕入れの状況によって変更になる場合がございます。
            </li>
          </ul>
        </LWrap>
      </section>
      {/* <LOgata /> */}
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
